import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Наші послуги | Послуги гірськолижного відпочинку Мигове
			</title>
			<meta name={"description"} content={"Кожна сніжна стежка веде до виняткового сервісу - відкрийте для себе Мигове"} />
			<meta property={"og:title"} content={"Наші послуги | Послуги гірськолижного відпочинку Мигове"} />
			<meta property={"og:description"} content={"Кожна сніжна стежка веде до виняткового сервісу - відкрийте для себе Мигове"} />
			<meta property={"og:image"} content={"https://swirlsoz.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://swirlsoz.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://swirlsoz.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://swirlsoz.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://swirlsoz.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://swirlsoz.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://swirlsoz.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				color="--primary"
			>
				Наші Послуги
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			У гірськолижному курорті Мигове наш спектр послуг розроблений таким чином, щоб кожен гість відчув вершину гірського відпочинку та спорту. Ось короткий огляд того, чого варто очікувати, коли ви приєднаєтесь до нас на саміті.
			</Text>
		</Section>
		<Section padding="0px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
				margin="0px 0px 30px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Схили
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						- Різноманітні траси: Незалежно від того, чи ви новачок, чи експерт, наш вибір трас пропонує відповідний виклик для вашого рівня майстерності.
						<br/><br/>
- Якість снігу: Регулярний догляд та системи штучного засніження підтримують ідеальні умови для катання на лижах та сноуборді.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Спорядження та прокат
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						- Висококласне спорядження: Прокат найновішого гірськолижного та сноубордичного спорядження, налаштованого до досконалості нашим досвідченим персоналом.                       <br/><br/>
- Послуги з екіпірування: Екіпіруйтеся з нашим асортиментом зимового одягу та захисного спорядження, розрахованого на будь-який вік і розмір.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Інструкція
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						- Приватні уроки: Індивідуальні тренування з сертифікованими інструкторами для покращення техніки та впевненості в собі.                     
<br/><br/>
- Групові заняття: Навчайтеся та розважайтеся з однолітками - заняття доступні для різних рівнів підготовки, з акцентом на безпеку та розвиток навичок.

						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Зручності
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						- Гірські будиночки: Відпочиньте та підкріпіться в наших комфортабельних будиночках, з яких відкривається панорамний вид на гори.                    
<br/><br/>
- Харчування: Вибір ресторанів від повсякденних до вишуканих, де подають страви місцевої та міжнародної кухні.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Сім'я та діти
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						- Дитячі програми: Заняття під наглядом і лижна школа для дітей, щоб вони насолоджувалися снігом так само, як і дорослі.                    
<br/><br/>
- Сімейні послуги: Зручності та послуги, орієнтовані на сім'ї, від догляду за дітьми до сімейних заходів.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Безпека та допомога
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						- Гірський патруль: висококваліфікований лижний патруль, готовий надати допомогу в екстрених ситуаціях.                    
<br/><br/>
- Пункти першої медичної допомоги: Обладнані для надання першої медичної допомоги, гарантуючи безпеку та благополуччя всіх гостей.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://swirlsoz.com/img/7.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					{" "}Щоб отримати повну інформацію:
				</Text>
				<Text font="--base">
					• Завітайте до нас: Відчуйте різницю Мигове особисто.
					<br />
					<br />
					• Зв'яжіться з нами: Наша команда готова відповісти на ваші запитання та допомогти спланувати ідеальну гірську подорож.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});